export const requireValue = warning => {
	return new Promise((res, rej) => {
		const list = Object.keys(warning);
		let canIgo = true;
		for (let i = 0; i < list.length; i++) {
			if (!String(this.whiteParams[list[i]])) {
				this.$message.error(warning[list[i]]);
				canIgo = false;
				break;
			}
		}
		canIgo ? res() : rej()
	})
};
export class storeChecked {
	constructor(key) {
		this.key = key;
		this.item = {};
	}
	setPage(list, page) {
		this.item[page] = list;

	}
	getpage(page) {
		return this.item[page] || []
	}
	getAll() {

		return Object.values(this.item).flat(Infinity)
	}
}
