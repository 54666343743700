export default (cfg) => {
	const { state, mutations, actions } = cfg;
	return {
		namespaced: true,
		state: {
			querySearch: {
				pageNum: 1,
				pageSize: 15,
			},
			total: 0,
			...state
		},
		mutations: {
			setQuerySearch(state, payload) {
				// state.querySearch = payload
			},
			setQuerySearchPageNum(state, payload) {
				console.log(payload || 1)
				state.querySearch.pageNum = payload || 1
			},
			setQuerySearchPagesize(state, payload) {
				state.querySearch.pageSize = payload || 15
			},
			setTotal(state, payload) {
				state.total = payload
			},
			...mutations
		},
		actions: {
			...actions
		}
	}
}