export default (list, buildName) => {
	const params = {};
	const buildParams = {};
	const storeBuild = {};  // 记录楼栋的Id 名称
	const topList = [];
	list.forEach(item => {
		if (params[item.buildingId + '-' + item.floorNo]) {   // 首先查找楼层
			params[item.buildingId + '-' + item.floorNo].push({ ...item, checked: !!item.checked, label: item.roomNo })
		} else {
			params[item.buildingId + '-' + item.floorNo] = [{ ...item, checked: !!item.checked, label: item.roomNo }]
		}
		if (buildParams['build-' + item.buildingId]) {
			if (!buildParams['build-' + item.buildingId][item.buildingId + '-' + item.floorNo]) {
				buildParams['build-' + item.buildingId][item.buildingId + '-' + item.floorNo] = { id: item.floorNo, label: '第' + item.floorNo + '层', children: params[item.buildingId + '-' + item.floorNo] }
			}
		} else {
			buildParams['build-' + item.buildingId] = { [item.buildingId + '-' + item.floorNo]: { id: item.floorNo, label: '第' + item.floorNo + '层', children: params[item.buildingId + '-' + item.floorNo] } }
		}
		storeBuild[item.buildingId] = buildName[item.buildingId] || item.buildingId
	})
	Object.keys(buildParams).forEach(item => {
		const id = item.split('-')[1];
		if (id != 'null' && id != null) {
			topList.push({ id, label: storeBuild[id], children: Object.values(buildParams[item]) })
		}
	});
	return topList

}