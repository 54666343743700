import { Message } from 'element-ui';
export default (content, fileName) => {
	const dom = document.createElement('a') // 隐藏的a标签，href为输出流
	const blob = new Blob([content]) // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
	const url = window.URL.createObjectURL(blob) // 生成一个Blob对象
	dom.style.display = 'none'
	dom.href = url
	document.body.appendChild(dom)
	dom.setAttribute('download', fileName) // 指示浏览器下载url,而不是导航到它
	dom.click()
	document.body.removeChild(dom)
	// 手动释放创建的URL对象所占内存
	URL.revokeObjectURL(url)
	Message({ message: "导出 " + fileName + " 成功", type: "success" })
}
