import axios, { $get, $post, $put, $delete, $postFile } from '../../plugins/axios';
import { getSchoolId } from '../../views/common/store'
import base from '../base';
export default base({
	namespaced: true,
	state: {
		querySearch: {
			pageNum: 1,
			pageSize: 15,
		},
		mustOpenId: -999,
		total: 0,
		sectionItem: null,
		searchValue: "",
		roomList: [],
		chooseType: "room",   //  查看房间管理类型：room/cell
		checkedCell: "",  // 选中的楼层
		checkedRoom: "",  // 选中的房间
		showAddCell: false,
		tableList: [],
		checkBuildInfo: null,  //  选中房间的信息
		roomSex: "",
		statisticalInformation: {
			buildingTotalNum: 0,
			"roomTotalNum": 0,
			"boyRoomNum": 0,
			"girlRoomNum": 0
		},
		buildId: "",
	},

	actions: {
		getRoomList({ commit }) {
			$get('/lock/buildingInfo').then(res => {
				if (res) {
					const list = res.map(it => ({
						...it,
						label: it.buildingName,
						children: new Array(it.floorNum).fill('').map((y, idx) => ({
							id: idx,
							label: `第${idx + 1}层`,
							buildingId: it.id
						}))
					}))
					commit("setRoomList", list);
					// commit("setTableList", list)
				}
			})
		},
		getCellData({ commit }, params) {   // 获取
			commit("setCheckedCell", params)
		},
		refreshRome({ commit, dispatch }) {
			$post('/lock/roomInfo/batchUpdateLivedInNumForRooms', { id: getSchoolId() }, {}).finally(() => {
				dispatch('getRoomData')
			})
		},
		changeRoomFunc({ commit, state }, params){
		return	$post ('/lock/roomInfo/exchangeRoomForStudents',params)
		},
		getRoomDataByParams({ commit, state }, params) {
			return $get('/lock/roomInfo/page', { params })
		},
		getRoomData({ commit, state }, params) {   // 获取房间表格数据
			const pa = { ...state.checkBuildInfo, ...state.querySearch, ...params, universityId: getSchoolId() };
			state.searchValue ? (pa.roomNo = state.searchValue) : delete pa.roomNo;
			$get('/lock/roomInfo/page', { params: pa }).then(res => {
				pa && commit('setCheckBuildInfo', pa)
				params && params.floorNo && commit("setCheckedRoom", params.floorNo)
				params && params.buildingId && commit("setBuildId", params.buildingId)
				commit("setChooseType", 'room');
				console.log(res);
				commit("setTableList", res.list)
				commit("setTotal", res.total)
				commit("setQuerySearchPageNum", pa.pageNum)
			})
		},
		addRoomData({ state, dispatch }, param) {
			console.log(param);
			const params = { ...state.checkBuildInfo, ...param, universityId: getSchoolId() };
			params.pageNum && delete params.pageNum;
			params.pageSize && delete params.pageSize;
			return $post('/lock/roomInfo', params).then(res => {
				dispatch('getRoomData');
				dispatch('getDtatisticalInformation')
				return res
			})
		},
		modifyRoomData({ state, dispatch }, params) {
			return $put('/lock/roomInfo', { ...state.checkBuildInfo, ...params, universityId: getSchoolId() }).then(res => {
				dispatch('getRoomData');
				return res
			})
		},
		addBuildCell({ state, dispatch, commit }, params) {
			commit('setMustOpenId', state.checkBuildInfo.buildingId);
			return $post('/lock/buildingInfo/addLastFloorForBuilding', { id: state.checkBuildInfo.buildingId, universityId: getSchoolId() }).then(res => {
				dispatch("getRoomList");
				commit('setMustOpenId', -999);
				return res
			})
		},
		deleteRoomData({ dispatch }, id) {
			return $delete('/lock/roomInfo/' + id).then(() => {
				dispatch('getRoomData');
				dispatch("getDtatisticalInformation")
			})
		},
		searchTable({ commit }, params) {
			///  搜索表格数据
			console.log(commit, params)
		},
		batAdd({ dispatch }, params) {
			///  批量添加
			return new Promise(response => {

				$post('/lock/buildingInfo/batchAddBatchBuildingInfos', { ...params, universityId: getSchoolId() }).then(() => {
					dispatch("getRoomList")  //  刷新页面
					// dispatch("getBuildData")  //  刷新页面
					dispatch('getAllData')
					dispatch("getDtatisticalInformation")
					response();
				})
			})
		},
		getAllData({ commit, dispatch, state }) {
			// if (state.chooseType != 'cell') {
			commit("setCheckedRoom", "")
			commit("setChooseType", 'room')
			commit("setSearchValue", "")
			// commit("setTableList", state.roomList)
			// }
			dispatch("getRoomData", {
				pageNum: 1,
				pageSize: 15,
				floorNo: "",
				roomNo: "",
				buildingId: "",
				id: ''
			})
		},
		async saveRoomInfo() {
			//  保存房间信息
			console.log('添加房子')
			return 'sss'
		},
		async searchStudentFux({ commit }, str) {
			console.log(str);
			commit("setStudentList", new Array(10).fill('1').map((it, idx) => ({ id: idx, name: "张三", IDcard: "434343434343", year: "2021", college: "计算机科学学院", system: "前端", sNo: "20210000" })))
		},
		getBuildData({ state, commit }, params) {    //  获取楼栋信息表格
			const pa = { ...state.querySearch, ...(params || {}), buildingName: state.searchValue, universityId: getSchoolId() }
			axios.get('/lock/buildingInfo/page', { params: pa }).then(res => {
				commit("setCheckBuildInfo", null)
				// commit("setTableList", res.list);
				commit("setTotal", res.total);
				commit("setQuerySearchPageNum", pa.pageNum)
			})
		},
		async addBuild({ dispatch }, params) {      //  新增楼栋
			$post('/lock/buildingInfo', params).then(() => {
				dispatch("getRoomList")  //  刷新页面
				// dispatch("getBuildData")  //  刷新页面
				dispatch('getAllData')
				dispatch("getDtatisticalInformation")
			})
		},
		async modifyBuild({ dispatch }, params) {    // 修改楼栋
			return new Promise(res => {
				$put('/lock/buildingInfo', { ...params, universityId: getSchoolId() }).then(() => {
					dispatch("getRoomList")  //  刷新页面
					// dispatch("getBuildData")  //  刷新页面

					res();
				})
			})
		},
		async deleteBuild({ dispatch }, id) {    // 修改楼栋
			return new Promise(res => {
				$delete(`/lock/buildingInfo/${id}`).then(() => {
					dispatch("getRoomList")  //  刷新页面
					// dispatch("getBuildData")  //  刷新页面
					dispatch('getAllData')
					dispatch("getDtatisticalInformation")
					res();
				})
			})
		},
		getDtatisticalInformation({ commit }) {
			$get('/lock/roomInfo/statisticsRoomNumForUniversity').then(res => {
				commit('setSticalInformation', res)
			})
		},
		async deleteCell({ dispatch, commit }, params) {
			$delete('/lock/buildingInfo/deleteLastFloorRooms', { data: { ...params, universityId: getSchoolId() } }).then(() => {
				dispatch("getDtatisticalInformation");
				dispatch("getRoomList")
				dispatch("getAllData")
				commit('setCheckedCell', -1)
			})
		},
		getAllRoomExcel() {
			axios.get('/lock/roomInfo/exportExcel', { params: { universityId: getSchoolId() }, responseType: "blob" }).then(res => {
				// download(res, '学生管理模板')
			})
		},
		getRoomExcelTemplate() {
			axios.get('/lock/roomInfo/downloadExcelTemplate', { responseType: "blob" }).then(res => {
				// download(res, '学生管理模板')
			})
		},
		uploadRoomExcelTemplate({ dispatch }, fd) {
			return new Promise((res, rej) => {
				$postFile('/lock/roomInfo/importExcel', fd).then(() => {
					dispatch('getRoomData');
					res();
				}).catch((err) => {
					dispatch('getRoomData');
					rej(err);
				})
			})
		},
		getErrRoomExcel({ state }, params) {
			console.log(state.name)
			$get('/lock/roomInfo/downloadErrorExcel', { params, responseType: "blob" }).then(res => {
				// download(res, '学生导入错误文档')
			})
		},
		getRoomExcel({ state, dispatch }, params) {
			if (!params) {

				$get('/lock/roomInfo/exportExcel', {
					params: {
						titleFlag: "0",
						exportIds: "0",
					}, responseType: "blob"
				})

			} else {
				if (params.exportIds) {
					$get('/lock/roomInfo/exportExcel', { params: { ...params, titleFlag: "1", }, responseType: "blob" }).then(res => {
						// download(res, '学生管理导出数据')
					})
				}
			}
		},
	},
	mutations: {

		setSectionItem: (state, payload) => {
			state.sectionItem = payload

		},
		setMustOpenId: (state, payload) => {
			state.mustOpenId = payload
		},
		setSticalInformation(state, payload) {
			console.log(payload)
			state.statisticalInformation = payload
		},
		setRoomList(state, payload) {
			state.roomList = payload
		},
		setChooseType(state, payload) {
			state.chooseType = payload
		},
		setCheckedCell(state, payload) {
			state.checkedCell = payload
		},
		setCheckedRoom(state, payload) {
			state.checkedRoom = payload
		},
		setShowAddCell(state, payload) {
			state.showAddCell = payload
		},
		setTableList(state, payload) {
			state.tableList = payload
		},
		setSearchValue(state, payload) {
			state.searchValue = payload
		},
		setCheckBuildInfo(state, payload) {
			state.checkBuildInfo = payload
		},
		setBuildId(state, payload) {
			state.buildId = payload
		},
		setRoomSex: (state, payload) => {
			state.roomSex = payload
		}
	},

})