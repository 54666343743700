import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "./element-variables.scss";
import CommonTable from './components/commonTable.vue';
import CommonPagination from './components/commonPaginations.vue';
import CommonDaoru from './components/commonDaoru.vue';
import CommonDaochu from './components/commonDaochu.vue';
import CommonRangeTime from './components/commonRangeTime.vue'
import MyInput from './components/myInput.vue'
import VCalendar from 'v-calendar';
Vue.config.productionTip = false;
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
Vue.component("common-table", CommonTable);
Vue.component("common-page", CommonPagination);
Vue.component("common-daoru", CommonDaoru);
Vue.component("common-daochu", CommonDaochu);
Vue.component("common-timer", CommonRangeTime);
Vue.component("my-input", MyInput);
// 正式环境清除所有console.log
if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = function () {};
  }
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
