<template>
  <el-button
    @mouseover.native="hover = true"
    @mouseleave.native="hover = false"
    v-bind="$attrs"
    v-on="$listeners"
    type="primaryHover"
  >
    <!-- <img
      v-if="!hover"
      class="icon-btn"
      src="@/assets/daoru.svg"
      alt=""
      srcset=""
    />
    <img
      v-else
      class="icon-btn"
      src="@/assets/daoru-hover.svg"
      alt=""
      srcset=""
    /> -->
    导入
  </el-button>
</template>

<script>
export default {
  name: "common-icon",
  data() {
    return {
      hover: false,
    };
  },
  mounted() {
    console.log(this.defaultImg);
  },
  props: {
    defaultImg: String,
    hoverImg: String,
  },
};
</script>

<style>
</style>