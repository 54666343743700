<template>
  <div class="d-f ai-c jc-sb">
    <span v-if="startName != 'none'" class="demonstration">{{
      startName
    }}</span>
    <el-date-picker
      v-model="value1"
      :picker-options="value1OPtion"
      :type="type"
      :clearable="clearable"
      placeholder="选择日期"
      @change="change"
    >
    </el-date-picker>
    <span class="demonstration d-f jc-c ai-c" style="margin-left: 5px">{{
      endName
    }}</span>
    <el-date-picker
      v-model="value2"
      :picker-options="value2OPtion"
      :type="type"
      :clearable="clearable"
      placeholder="选择日期"
      @change="change"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  name: "common-range-time",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    startName: {
      type: String,
      default: "开始时间：",
    },
    endName: {
      type: String,
      default: "结束时间：",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "date",
    },
  },
  data() {
    return {
      value1: "",
      value2: "",
    };
  },
  methods: {
    returnValue() {
      this.$emit("input", [this.value1, this.value2]);
      this.$emit("change", [this.value1, this.value2]);
    },
    change() {
      this.$emit("changeRange", [this.value1, this.value2]);
    },
  },
  mounted() {
    const val = this.value;
    val[0] && (this.value1 = val[0]);
    val[1] && (this.value2 = val[1]);
  },
  computed: {
    value1OPtion() {
      return {
        disabledDate: (time) => {
          return this.value2 ? time.getTime() > new Date(this.value2) : false;
        },
      };
    },
    value2OPtion() {
      return {
        disabledDate: (time) => {
          return this.value1 ? time.getTime() < new Date(this.value1) : false;
        },
      };
    },
  },
  watch: {
    value1(val) {
      this.returnValue();
    },
    value2() {
      this.returnValue();
    },
    value(val) {
      console.log(val);
      val[0] && (this.value1 = val[0]);
      val[1] && (this.value2 = val[1]);
    },
  },
};
</script>

<style>
.demonstration {
  width: 80px;
}
</style>