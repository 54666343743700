// import axios from '../plugins/axios';
import axios, { $get, $post, $put, $delete, $postFile, startLoading, endLoading } from '../../plugins/axios';
import { getSchoolId } from '../../views/common/store'
import getBuildFloorRoom from '../../views/common/getBuildFloorRoom';
import base from '../base'
export default base({
	namespaced: true,
	state: {
		sectionItem: null,   //  表格多选的item
		tableList: [],
		searchValue: "",
		managerList: [],
		masterRoomList: [],
		roomNum: 0,
		masterName: "",
		moreInfo: {}
	},
	actions: {

		getTableList({ commit, state }, param) {
			$get('/lock/dormInfo/page', { params: { ...state.querySearch, ...state.moreInfo, ...param, complexCondition: state.searchValue, universityId: getSchoolId() } }).then(res => {
				commit("setTableList", res.list)
				commit("setTotal", res.total)
			})
		},
		addMaster({ dispatch }, params) {
			return $post('/lock/dormInfo', { ...params, universityId: getSchoolId() }).then(res => {
				dispatch('getTableList');
				return res
			})
		},
		modifyMaster({ dispatch }, params) {
			return $put('/lock/dormInfo', { ...params, universityId: getSchoolId() }).then(res => {
				dispatch('getTableList');
				return res
			})
		},
		deleteMaster({ dispatch }, id) {
			return $delete('/lock/dormInfo/' + id).then(() => {
				dispatch('getTableList');
			})
		},
		batchDel({ dispatch }, ids) {
			if (ids && ids.length) {
				Promise.all(ids.map(it => $delete(`/lock/dormInfo/${it}`))).then(() => {
					dispatch('getTableList')
				})
			}
		},
		postMasterRoomList({ commit }, params) {
			return new Promise(response => {
				console.log(commit)
				return $post('/lock/dormsRooms/dormBatchAddBatchRooms', params).then(() => {
					response()
				})
			})
		},
		getMasterRoomList({ commit, dispatch }, id) {

			return $get('/lock/dormsRooms/findRoomListByDormId', { params: { dormId: id } }).then((res) => {
				commit("setMasterList", res);
				dispatch("getAllRooms")
				// dispatch('getTableList');
			})
		},
		getHoustMasterType() {
			return $get("/lock/systemParameter", { doNotNeedLoading: true })
		},
		getExcelTemplate() {
			axios.get('/lock/dormInfo/downloadExcelTemplate', { responseType: "blob" }).then(res => {
				// download(res, '宿管管理模板')
			})
		},
		uploadExcelTemplate({ dispatch }, fd) {
			return new Promise((res, rej) => {
				$postFile('/lock/dormInfo/importExcel', fd).then(() => {
					dispatch('getTableList');
				}).catch(rej)
			})
		},
		getExcel({ state }, params) {
			if (!params) {

				$get('/lock/dormInfo/exportExcel', {
					params: {
						titleFlag: "0",
						exportIds: "0",
					}, responseType: "blob"
				})

			} else {
				if (params.exportIds) {
					$get('/lock/dormInfo/exportExcel', { params: { ...params, titleFlag: "1", }, responseType: "blob" }).then(res => {
						// download(res, '宿管管理导出数据')
					})
				}
			}
		},
		getAllRooms({ commit, state }) {
			$get('/lock/buildingInfo').then(response => {
				let buildingNameObject = {};
				response.forEach((item) => {
					buildingNameObject[item.id] = item.buildingName;
				});
				$get('/lock/roomInfo').then(res => {
					let resList = res;
					if (state.masterRoomList.length) {
						const roomIds = state.masterRoomList.map(it => it.roomId);
						resList.forEach(item => {
							if (roomIds.includes(item.id)) {
								item.checked = true
							}
						})
					}
					commit("setRoomNum", resList.length)
					commit('setManageList', getBuildFloorRoom(resList, buildingNameObject))
				})
			})
		},
		getBuildRoomList() {
			return $get('/lock/buildingInfo')
		},
		getErrMasterExcel({ state }, params) {
			$get('/lock/dormInfo/downloadErrorExcel', { params, responseType: "blob" }).then(res => {
				// download(res, '宿管管理错误文档')
			})
		},
		batAddHostMaster({ dispatch }, list) {

			//  复制了学生端的代码，可以封装，只需要改新增的地址，懒的封装
			return new Promise(res => {
				let resonList = [];
				const batAddHostMaster = (idx = 0) => {
					const params = list[idx];
					if (!params) {
						endLoading();
						res(resonList)
						return;
					}
					params.dormName = params.name;
					delete params.reason;
					delete params.name;
					$post('/lock/dormInfo', params, { doNotNeedLoading: true, doNotNeedMessage: true }).then(res => {

					}).catch(e => {
						params.reason = e.data.message;
						params.name = params.dormName;
						resonList.push(params);
					}).finally(() => {
						batAddHostMaster(idx + 1)
					})
				}
				if (list.length) {
					startLoading();
					batAddHostMaster()
				}

			})
		}
	},

	mutations: {
		setMasterList(state, payload) {
			state.masterRoomList = payload
		},
		setSectionItem: (state, payload) => {
			state.sectionItem = payload
		},
		setTableList: (state, payload) => {
			console.log(payload)
			state.tableList = payload
		},

		setSearchValue(state, payload) {
			state.searchValue = payload
		},
		setManageList(state, payload) {
			state.managerList = payload
		},
		setRoomNum(state, payload) {
			state.roomNum = payload
		},
		setMasterName(state, payload) {
			state.masterName = payload
		},
		setMoreInfo(state, payload) {
			state.moreInfo = payload
		}
	},
})