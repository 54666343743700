import ajax, { $get, $post, $put } from '../../plugins/axios'
import { getOrgId, getSchoolId } from '../../views/common/store'
export default {
	namespaced: true,
	state: {
		settingBaseinfo: [],
		schoolMessaage: null,
		academyList: [],
		allDays: [],
	},
	actions: {
		changePutIdentity({ dispatch }, params) {
			return $put('/lock/universityInfo', params)
		},
		getAllSettingMessage({ commit }) {
			return new Promise(response => {

				$get("/lock/systemParameter", { doNotNeedLoading: true }).then(res => {
					commit("setSetting", res)
					response(res);
				})
			})
		},
		updateSettingInfo({ dispatch }, params) {
			ajax.put('/lock/systemParameter', params, { doNotNeedLoading: true }).then(() => {
				dispatch('getAllSettingMessage')
			})
		},
		addSettingInfo({ dispatch }, params) {
			$post('/lock/systemParameter', params, { doNotNeedLoading: true }).then(() => {
				dispatch('getAllSettingMessage')
			})
		},
		getCalanderInfo({ commit, state }, year) {
			return new Promise(response => {
				$get("/lock/clockInCalendar", { params: { year: year || new Date().getFullYear() } }).then(res => {
					if (res.length == 0) {   /// 初始化没有节假日数据
						$post('/lock/clockInCalendar', { year: year || new Date().getFullYear() }).then(d => {
							$get("/lock/clockInCalendar", { params: { year: year || new Date().getFullYear() } }).then(fs => {
								console.log(fs)
								commit('setAllDays', (year == new Date().getFullYear() || !year) ? fs : state.allDays.find(it => it.calendarDate == `${year}-01-01`) ? fs : [...state.allDays, ...fs]);
								response(state.allDays)
							})
						})
					} else {
						console.log(res)
						commit('setAllDays', year == new Date().getFullYear() || !year ? res : state.allDays.find(it => it.calendarDate == `${year}-01-01`) ? res : [...state.allDays, ...res]);
						response(state.allDays)
					}
				})
			})
		},
		getCurrentSchoolMessage({ commit }) {   // 获取当前
			ajax.get('/lock/universityInfo').then(res => {
				for (let item of res) {
					if (item.id == getSchoolId()) {
						commit("setSchoolMessage", item)
					}
				}
			})
		},
		changePhoneBySetting({ state }, params) {
			return new Promise((res, rej) => {
				if (state.schoolMessaage) {
					ajax.put('/lock/universityInfo', { userId: state.schoolMessaage.userId, id: state.schoolMessaage.id, ...params }).then(() => {
						res()
					})
				} else {
					rej()
				}
			})
		},
		changeSchoolMessage({ state }, params) {
			return new Promise((res, rej) => {
				if (state.schoolMessaage) {
					ajax.post('/lock/universityInfo/updateUniversityInfoPassword', { userId: state.schoolMessaage.userId, id: state.schoolMessaage.id, ...params }).then(() => {
						res()
					})
				} else {
					rej()
				}
			})
		},
		changeDays({ state }, params) {
			return $put('/lock/clockInCalendar', params, { doNotNeedLoading: true })
		},
		batChangeDays({ state }, params) {
			return $put('/lock/clockInCalendar/updateClockInCalendarListClockSettingBy', params, { doNotNeedLoading: true })
		},
		getClockTimeRange() {
			// $get('/lock/clockInCalendar').then(res=>{
			// 	console.log(res)
			// })
		},
		getAccList({ commit }) {
			ajax.get('/lock/organizationInfo/getUniversityAllOrganizationInfos', { params: { id: getOrgId() } }).then(res => {
				commit('setacademyList', res)
			})
		},
		addAcc({ dispatch }, params) {
			ajax.post('/lock/organizationInfo', params).then(() => {
				dispatch("getAccList")
			})
		},
		modifyAcc({ dispatch }, params) {
			ajax.put('/lock/organizationInfo', params).then(() => {
				dispatch("getAccList")
			})
		},
		deleteAcc({ dispatch }, id) {
			ajax.delete(`/lock/organizationInfo/${id}`).then(() => {
				dispatch("getAccList")
			})
		},

		async batchPutRangeCalander({ state }, params) {

			const init = {
				ids: state.allDays.map(it => it.id).join(','),
				clockSetting: ""
			}
			await $put("/lock/clockInCalendar/updateClockInCalendarListClockSettingBy", init)
			if (params.clockSetting && params.clockSetting !== '1;Invalid Date-Invalid Date') {
				await $put("/lock/clockInCalendar/updateClockInCalendarListClockSettingBy", params)
			}
			return 1

		}
	},
	mutations: {
		setSetting(state, payload) {
			state.settingBaseinfo = payload
		},
		setSchoolMessage(state, payload) {
			state.schoolMessaage = payload
		},
		setacademyList(state, payload) {
			state.academyList = payload
		},
		setAllDays(state, payload) {
			const idx = state.allDays.findIndex(it => it.calendarDate == payload[0].calendarDate);

			if (idx > -1) {

				state.allDays = [...state.allDays.slice(0, idx), ...payload, ...state.allDays.slice(idx + payload.length)];
			} else {

				state.allDays = [...state.allDays, ...payload]
			}
		}
	}
}