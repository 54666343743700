// import axios from '../plugins/axios';
import axios, { $get, $post } from '../../plugins/axios'
import { getOrgId, getSchoolId } from '../../views/common/store'
import base from '../base'
import dayjs from 'dayjs'
export default base({
	state: {
		sectionItem: null,   //  表格多选的item
		tableList: [],
		warnTableList: [],
		warnTotal: 0,
		unclockNumber: 0,
		unclockList: [],
		daterange: [],
		top100List: [],
		querySearch: {},
		unclockEveryDayList: [],
		timer:false
	},
	actions: {
		getTableList({ commit, state }, params) {   // 获取未打卡列表
			if (state.timer) {
				clearTimeout(state.timer)
			}
			state.timer = setTimeout(() => {
			$get('/lock/clockInRecord/page', { params: { ...state.querySearch, ...params } }).then(res => {
				state.querySearch = { ...state.querySearch, ...params }
				commit("setTableList", res.list)

				commit("setTotal", res.total)
			})
		})
		},
		getAllTableList({ commit }, params) {
			console.log(commit)
			return $get("/lock/clockInRecord/statisticsFirstHundredUnClockInPeople", { params })
		},
		getUnClockNumber({ commit }, params) {    //  获取未打卡院系数量
			return new Promise(response => {
				console.log(params, params.startTime ? dayjs(params.startTime).format('YYYY-MM-DD') : "")
				$get('/lock/clockInRecord/statisticsUnClockInPeopleNumAtAcademy', {
					params: {
						startTime: params.startTime ? dayjs(params.startTime).format('YYYY-MM-DD') : "",
						endTime: params.endTime ? dayjs(params.endTime).format('YYYY-MM-DD') : "",

					},

				}).then(res => {
					commit("setUnclockNumber", res.reduce((c, n) => c + +n.peopleNum, 0));
					commit("setUnclockList", res);
					response(res)
				})
			})
		},

		getAcademyList() {    //  获取学院列表
			return new Promise(response => {
				$get('/lock/organizationInfo/getUniversityAllOrganizationInfos', { params: { id: getOrgId() } }).then(res => {

					response(res)
				})
			})
		},
		getTop100Unclock({ commit }, params) {   //  获取排名100未打卡人数
			$get('/lock/clockInRecord/statisticsFirstHundredUnClockInPeople', { params }).then(res => {
				commit('setTop100List', res)
			})
		},
		getUnclockExcel({ state }, params) {
			if (!params) {
				$get('/lock/clockInRecord/page', { params: { ...state.querySearch, pageSize: state.total, pageNum: 1 } }).then(res => {
					$get('/lock/clockInRecord/exportStatisticsExcel', {
						params: {
							exportIds: res.list.map(it => it.id).join(","),
						}, responseType: "blob"
					})
				})
			} else {

				$get('/lock/clockInRecord/exportStatisticsExcel', { params, responseType: "blob" }).then(res => {
					// download(res, '未打卡记录导出数据')
				})
			}
		},
		getClockExcel({ state }, params) {
			if (!params) {

				$get('/lock/clockInRecord/exportExcel', {
					params: {
						titleFlag: "0",
						exportIds: "0",
					}, responseType: "blob"
				})

			} else {
				if (params.exportIds) {
					$get('/lock/clockInRecord/exportExcel', { params: { ...params, titleFlag: "1", }, responseType: "blob" }).then(res => {
						// download(res, '打卡记录导出数据')
					})
				}
			}
		},
		getTop100Excel({ state }, params = {}) {
			$get('/lock/clockInRecord/exportStatisticsFirstHundredUnClockInPeopleExcel', { params, responseType: "blob" }).then(res => {
				// download(res, '学生管理导出数据')
			})
		},
		getWarnTable({ commit, state }, params) {

			return $get('	/lock/alarmInfo/page', { params })

		},
		getEveryDayUnclockNumber({ commit }, params) {
			$get('/lock/clockInRecord/statisticsUnClockInPeopleNumAtAcademyByDay', { params }).then(res => {
				commit("setUnclockEveryDayList", res)

			})
		},
		addWarnData() {
			const params = new Array(100).fill('').map((it, idx) => ({
				"universityId": getSchoolId(),
				"studentId": idx,
				"studentName": "张三" + idx,
				"roomId": idx % 25,
				"roomNo": idx % 25 + '01',
				"orgInfo": "计算机系",
				"alarmType": "1",
				"readFlag": "0",
				"alarmContent": "未打卡" + idx % 5 + '次',
				"alarmDate": "",
				"alarmStartDate": dayjs().add(-Math.random() * 10 >>> 0, 'day').format('YYYY-MM-DD'),
				"alarmEndDate": dayjs().format('YYYY-MM-DD'),
				"complexCondition": "",
				"ids": ""
			}))

			$post('/lock/alarmInfo', params[Math.random() * 100 >>> 0])
		},
		batSetRead({ state }, ids) {
			$post('/lock/alarmInfo/batchUpdateAlarmInfoAlreadyRead', { ids })
		},
		getAllWarnInfoExcel({ state }, readFlag) {
			axios.get('/lock/alarmInfo/exportExcel', { params: { exportIds: 0, universityId: getSchoolId() }, responseType: "blob" }).then(res => {
				// download(res, '学生管理模板')
			})
		},
	},

	mutations: {

		setSectionItem: (state, payload) => {
			state.sectionItem = payload
		},
		setTableList: (state, payload) => {
			state.tableList = payload
		},
		setWarnTableList: (state, payload) => {
			state.warnTableList = payload
		},
		setUnclockNumber(state, payload) {
			state.unclockNumber = payload
		},
		setUnclockList(state, payload) {
			state.unclockList = payload
		},
		setDateRange(state, payload) {
			state.daterange = payload;
		},
		setTop100List(state, payload) {
			state.top100List = payload
		},
		setUnclockEveryDayList(state, payload) {
			state.unclockEveryDayList = payload
		}
	},
})