import Vue from "vue";
import VueRouter from "vue-router";
import Layouts from "../views/Layouts.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layouts",
    component: Layouts,
    redirect: "/room",
    children: [
      {
        path: "room",
        component: () => import(/*webpackChunkName:"room" */"../views/room/index.vue")
      },
      {
        path: "student",
        component: () => import(/*webpackChunkName:"student" */"../views/student/index.vue")
      },
      {
        path: "unlock",
        component: () => import(/*webpackChunkName:"unlock" */"../views/unlock/index.vue")
      },
      {
        path: "clock",
        component: () => import(/*webpackChunkName:"clock" */"../views/clock/index.vue")
      },
      {
        path: "clock/warnSet",
        component: () => import(/*webpackChunkName:"clock-warn-set" */"../views/clock/clockWarn.vue")
      },
      {
        path: "clock/analysis",
        component: () => import(/*webpackChunkName:"clock-analysis-set" */"../views/clock/analysis.vue")
      },
      {
        path: "housemaster",
        component: () => import(/*webpackChunkName:"housemaster" */"../views/housemaster/index.vue")
      },
      {
        path: "housemaster/manager/:id",
        component: () => import(/*webpackChunkName:"housemaster-manager" */"../views/housemaster/manager.vue")
      },
      {
        path: "setting",
        component: () => import(/*webpackChunkName:"setting" */"../views/setting/index.vue")
      },
      {
        path: "setting/system",
        component: () => import(/*webpackChunkName:"setting-system" */"../views/setting/systemManag.vue")
      },

    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  }, {
    path: "/mm",
    component: () => import(/*webpackChunkName:"setting-system" */"../views/mm/index.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
