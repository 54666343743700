import Vue from "vue";
import {Popconfirm, FormItem,Form,Loading, CheckboxGroup, Tree, RadioGroup, Radio, Alert, Switch, Tag, Badge, MessageBox, TimePicker, Upload, OptionGroup, DatePicker, Dropdown, DropdownItem, DropdownMenu, Pagination, Select, Option, Drawer, Button, Message, Input, Checkbox, Dialog, Popover, Tabs, TabPane, Collapse, CollapseItem, Icon, Table, TableColumn } from "element-ui";

Vue.use(Button);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(Dialog);
Vue.use(Popover);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Icon);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Drawer);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Pagination);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(DatePicker);
Vue.use(Upload);
Vue.use(TimePicker);
Vue.use(Badge);
Vue.use(Switch);
Vue.use(Tag);
Vue.use(Alert);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Tree);
Vue.use(CheckboxGroup);
Vue.use(Loading);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Popconfirm)
Vue.prototype.$ELEMENT = { size: 'small' };
Vue.prototype.$message = ['error', 'success'].reduce((c, n) => ({ ...c, [n]: val => Message({ message: val, type: n }) }), {})
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$confirm = MessageBox.confirm;
