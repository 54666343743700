// import axios from '../plugins/axios';
import { $get, $post, $put, $delete } from '../../plugins/axios';
import base from '../base'
export default base({
	namespaced: true,
	state: {
		sectionItem: null,   //  表格多选的item
		tableList: [],
		timer: false,
	},
	actions: {
		getTableList({ commit, state }, param) {
			if (state.timer) {
				clearTimeout(state.timer)
			}
			state.timer = setTimeout(() => {
				$get('/lock/unlockingRecord/page', { params: { ...state.querySearch, ...param } }).then(res => {
					state.querySearch = { ...state.querySearch, ...param }
					commit("setTableList", res.list)
					commit("setTotal", res.total)
				})
			}, 500);
		},
		addMaster({ dispatch }, params) {
			return $post('/lock/dormInfo', { ...params }).then(res => {
				dispatch('getTableList');
				return res
			})
		},
		modifyMaster({ dispatch }, params) {
			return $put('/lock/dormInfo', { ...params }).then(res => {
				dispatch('getTableList');
				return res
			})
		},
		deleteMaster({ dispatch }, id) {
			return $delete('/lock/dormInfo/' + id).then(() => {
				dispatch('getTableList');
			})
		},
		getExcel({ state }, params) {
			if (!params) {

				$get('/lock/unlockingRecord/exportExcel', {
					params: {
						titleFlag: "0",
						exportIds: "0",
					}, responseType: "blob"
				})


			} else {
				if (params.exportIds) {
					$get('/lock/unlockingRecord/exportExcel', { params: { ...params, titleFlag: "1", }, responseType: "blob" }).then(res => {
						// download(res, '开锁管理导出数据')
					})
				}
			}
		},

	},

	mutations: {

		setSectionItem: (state, payload) => {
			state.sectionItem = payload
		},
		setTableList: (state, payload) => {
			console.log(payload)
			state.tableList = payload
		},
	},
})