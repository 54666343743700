// import axios from '../plugins/axios';
import axios, { $get, $post, $put, $delete, $postFile, startLoading, endLoading } from '../../plugins/axios';
import { getSchoolId, getOrgId } from '../../views/common/store';
import base from '../base';
export default base({
	namespaced: true,
	state: {
		yearsList: [],
		facultyList: [],   //  院系列表
		tableList: [],
		yearOver: 6,
		loading: false,
		unStudentsTotal: 0,
		checkedRoom: "",
		querySearch: {},
		ucationList: [
			{ id: "0", name: "专科" },
			{ id: "1", name: "本科" },
			{ id: "2", name: "硕士" },
			{ id: "3", name: "博士生" },
			{ id: "4", name: "无" }
		],   // 学历列表
		yaersList: new Array(6).fill('').map((tt, tdx) => ({
			id: new Date().getFullYear() - tdx,
			label: new Date().getFullYear() - tdx
		})),   //  学年列表
		sectionItem: null,   //  表格多选的item
		roomList: [], //  房号列表
		buildList: [],
		educationList: [
			{ id: "0", name: "专科" },
			{ id: "1", name: "本科" },
			{ id: "2", name: "硕士" },
			{ id: "3", name: "博士" },
			{ id: "4", name: "无" },
		],
		storeAddInfo: {},
		showAcademy: true,
		checkedCell: "",  // 选中的楼层
	},

	actions: {
		getFacultyList({ commit }) {
			/// 获取楼栋列表
			commit("setFacultyList", [
				{
					id: 1, label: '土木工程', children: [
						{
							id: 1, label: "土建", children: [
								{ id: 1, label: '2019' },
								{ id: 2, label: '2020' },
								{ id: 3, label: '2021' },
							]
						},
						{
							id: 1, label: "给排水", children: [
								{ id: 1, label: '2019' },
								{ id: 2, label: '2020' },
								{ id: 3, label: '2021' },
							]
						},
						{
							id: 1, label: "测绘", children: [
								{ id: 1, label: '2019' },
								{ id: 2, label: '2020' },
								{ id: 3, label: '2021' },
							]
						},

					]
				}, {
					id: 2, label: '计算机', children: [{
						id: 1, label: "计算机", children: [
							{ id: 1, label: "2020" }
						]
					}]
				}, {
					id: 3, label: '化学'
				}
			])

		},
		resetStudentPass({ dispatch }, userId) {
			return $put('/lock/user/resetPassword', { userId })
		},
		deleteStudent({ dispatch }, id) {
			$delete(`/lock/studentInfo/${id}`).then(() => {
				dispatch('getStudentData')
			})
		},
		getStudentInfo({ dispatch }, id) {
			return $get(`/lock/studentInfo/${id}`)
		},
		batchDelStudent({ dispatch }, ids) {
			if (ids && ids.length) {
				Promise.all(ids.map(it => $delete(`/lock/studentInfo/${it}`))).then(() => {
					dispatch('getStudentData')
				})
			}
		},
		addStudentInfo({ dispatch }, params) {
			return new Promise(response => {
				$post('/lock/studentInfo', params).then(() => {
					dispatch('getStudentData');
					response();
				})
			})
		},
		getStudentData({ commit, state }, params) {
			return new Promise((response) => {
				$get('/lock/studentInfo/page', { params: { ...state.querySearch, ...params } }).then(res => {
					commit("setTableList", res.list);
					commit("setTotal", res.total);
					commit('setQuerySearch', { ...state.querySearch, ...params });
					response()
				})
			})
		},
		editStudentData({ dispatch }, params) {
			return new Promise(response => {
				$put('/lock/studentInfo', params).then(() => {
					dispatch('getStudentData');
					response();
				})
			})
		},
		getOrgList({ state, commit }) {  //  获取院系相关信息
			$get('/lock/organizationInfo/getUniversityAllOrganizationInfos', { params: { id: getOrgId() } }).then(res => {
				$get("/lock/systemParameter", { doNotNeedLoading: true }).then(params => {
					const item = params.find(
						(it) => it.paramCode == "schoolYearRange"
					)?.paramValue?.split('-');
					$get('/lock/studentInfo/findStudentEnrolYearByUniversityId').then(years => {
						state.yearsList = years;
						const ls = item && item.length == 2 ? item?.map(Number).reduce((c, n) => n - c) + 1 : 6;
						const getData = (list, index) => list.map(it => ({
							id: it.id,
							label: it.orgName,
							children: it.subOrganizationList && it.subOrganizationList.length ? getData(it.subOrganizationList || [], index + 1) :
								years.map((tt, tdx) => ({
									id: tt,
									label: tt,
									type: 'year'
								}))
						}))
						const list = getData(res, 0);
						console.log(list)
						commit("setFacultyList", list)
					})
				})
			})
		},
		getAdacyList() {
			$get('/lock/systemParameter').then(res => {
				console.log(res)
			})
		},
		getRoomInfo({ commit }, buildingId) {
			$get('/lock/roomInfo', { params: { buildingId } }).then(res => {
				commit("setRoomList", res.map(it => ({ ...it, label: it.roomNo })))
			})
		},
		getBuildInfo({ commit }) {
			$get('/lock/buildingInfo').then(res => {
				commit("setBuildList", res.map(it => ({ ...it, label: it.buildingName })))
			})
		},
		getStudentNumbers({ commit, state }) {
			$get('/lock/studentInfo/statisticsStudentNumForUniversity').then(res => {
				state.unStudentsTotal = res.unAllocateGirlNum + res.unAllocateBoyNum
			})
		},
		getYearsByUniversityId({ commit, state }) {
			return $get('/lock/studentInfo/findStudentEnrolYearByUniversityId')
		},
		getUploadStatus({ commit, state }, id) {
			return axios.get('/lock/studentInfo/getAsyncTaskStatusById', { params: { id }, doNotNeedLoading: true })
		},
		getStudentExcelTemplate() {
			axios.get('/lock/studentInfo/downloadExcelTemplate', { responseType: "blob" }).then(res => {
				// download(res, '学生管理模板')
			})
		},
		getRoomExcelTemplate() {
			axios.get('/lock/studentInfo/downloadExchangeRoomExcelTemplate', { responseType: "blob" }).then(res => {
				// download(res, '学生管理模板')
			})
		},
		finallyUploadFunc({ dispatch }) {
			$put('/lock/roomInfo/batchUpdateLivedInNumForRooms').finally(() => {
				dispatch('getStudentData');

			})
		},
		uploadStudentExcelTemplate({ dispatch }, fd) {
			return $postFile('/lock/studentInfo/importExcel', fd, { doNotNeedLoading: true })
		},
		uploadRoomExcelTemplate({ dispatch }, fd) {
			return $postFile('/lock/studentInfo/importExchangeRoomExcel', fd, { doNotNeedLoading: true })
		},
		getErrStudentExcel({ state }, params) {
			console.log(state.name)
			$get('/lock/studentInfo/downloadErrorExcel', { params, responseType: "blob" }).then(res => {
				// download(res, '学生导入错误文档')
			})
		},
		getErrRoomExcel({ state }, params) {

			$get('/lock/studentInfo/downloadExchangeRoomErrorExcel', { params, responseType: "blob" }).then(res => {
				// download(res, '学生导入错误文档')
			})
		},
		getStudentExcel({ state, dispatch }, params) {
			if (!params) {

				$get('/lock/studentInfo/exportExcel', {
					params: {
						titleFlag: "0",
						exportIds: "0",
					}, responseType: "blob"
				})

			} else {
				if (params.exportIds) {
					$get('/lock/studentInfo/exportExcel', { params: { ...params, titleFlag: "1", }, responseType: "blob" }).then(res => {
						// download(res, '学生管理导出数据')
					})
				}
			}
		},
		allocateStudentAccommodation({ dispatch }, allocationWay) {
			$put('/lock/studentInfo/allocateStudentAccommodation', { allocationWay }).finally(() => {
				dispatch('getStudentData');
			})
		},
		batchUpdateStudents() {
			return $put('/lock/roomInfo/batchUpdateLivedInNumForRooms')
		},
		deleteBatStudent({ dispatch }, data) {
			return $delete('/lock/studentInfo', { data: { ...data, universityId: getSchoolId() } })
		},
		batAddStudents({ dispatch }, list) {
			return new Promise(res => {
				let resonList = [];
				const batAddStudents = (idx = 0) => {
					const params = list[idx];
					if (!params) {
						endLoading();
						dispatch('getStudentData');
						res(resonList)
						return;
					}
					delete params.reason;
					$post('/lock/studentInfo', params, { doNotNeedLoading: true, doNotNeedMessage: true }).then(res => {

					}).catch(e => {
						params.reason = e.data.message;
						resonList.push(params);
					}).finally(() => {
						batAddStudents(idx + 1)
					})
				}
				if (list.length) {
					startLoading();
					batAddStudents()
				}

			})
		}
	},
	mutations: {
		setLoading: (state, val) => { state.loading = val },


		setFacultyList: (state, payload) => {
			state.facultyList = payload;
		},
		setTableList: (state, payload) => {
			console.log(payload)
			state.tableList = payload
		},
		setEducetionList: (state, payload) => {
			state.educationList = payload
		},
		setYearList: (state, payload) => {
			state.yaersList = payload
		},
		setSectionItem: (state, payload) => {
			state.sectionItem = payload

		},
		setCheckedCell(state, payload) {
			state.checkedCell = payload
		},
		initQuery(state) {
			state.querySearch = {
				pageNum: 1,
				pageSize: 50,
			}
		},
		setRoomList(state, payload) {
			state.roomList = payload
		},
		setBuildList(state, payload) {
			state.buildList = payload
		},
		setStoreAddInfo(state, payload) {
			state.storeAddInfo = payload
		},
		setQuerySearch(state, payload) {
			state.querySearch = payload
		},

		setShowAcademy(state, payload) {
			state.showAcademy = payload
		},
		setcheckedRoom(state, payload) {
			state.checkedRoom = payload
		}
	},
})