import md5 from './md5';
const us_er = "S_T_U_S_R_G_D_R_S_S_L";
const time_span = "T_D_S_P_A_N_D_D_T";
const to_ken = "D_L_K_D_L_R_G_P_R_R";
const md5555 = "md555555555555555555555555555";
const rmkey = "DFGDFG_DFGDFGCV_RWERDFG_IOOPGHFGN";
const schoolID = "DF_GH_ER_H_R_U";
const orgId = "orgid";
const changeStore = "S_J_T_ER_G_M_X_A_F";
const identityNo__6 = 'DFGD_DFG_FGDF_GHF'
const time = 7;    // 免密登录天数设置
var store = window.localStorage.getItem(changeStore) && window.sessionStorage.getItem('Authorization') ? window.sessionStorage : window.localStorage;
export const changStore = (sTore) => { store.setItem(changeStore, '1'); store = sTore }
export const isMM = () => store == window.sessionStorage;
import { Message } from 'element-ui'
const setToken = (user) => {
	const timespan = +new Date();
	store.setItem(us_er, user);     // 存储用户
	store.setItem(time_span, timespan);   // 存储时间戳
	store.setItem(to_ken, md5(user + md5555 + timespan));
}
const setSchoolId = (id) => {
	store.setItem(schoolID, id);
}
const setIdentityNo = (id) => {
	store.setItem(identityNo__6, id);
}
const setOrgId = id => store.setItem(orgId, id);
const getOrgId = () => store.getItem(orgId);
const isLogin = () => {
	const user = store.getItem(us_er);
	const timespan = store.getItem(time_span);
	const token = store.getItem(to_ken);
	if (!user || !time_span || md5(user + md5555 + timespan) != token) {
		// Message({
		// 	message: '登录出错',
		// 	type: 'error'
		// });
		return false;
	}
	if (+new Date() - timespan > time * 1000 * 60 * 60 * 24) {
		// Message({
		// 	message: '登录超时，请重新登录',
		// 	type: 'error'
		// });
		return false;
	}
	return true;
}
const getRememberItem = () => {
	const rememberItem = store.getItem(rmkey);

	if (rememberItem) {
		const obj = JSON.parse(rememberItem);
		console.log(obj)
		if (obj.y && obj.g) {
			const { y, g } = obj;
			return { username: y, password: g }
		}
	}
	return ""
}
const setRememberItem = (userName, password) => {   // 保存账号密码。打乱
	let rem1 = {};
	let rem2 = {};
	let rem3 = {};
	for (let i = 0; i < 50; i++) {
		rem1[String(Math.random())] = String(Math.random())
		rem2[String(Math.random())] = String(Math.random())
		rem3[String(Math.random())] = String(Math.random())
	}
	store.setItem(rmkey, JSON.stringify({ ...rem1, y: userName, ...rem2, g: password, ...rem3 }))
}
const clearRemember = () => store.setItem(rmkey, "")
const clear = () => {
	store = window.localStorage;
	const re = getRememberItem();
	store.clear();
	if (re) {
		const { username, password } = re;
		setRememberItem(username, password)
	}
	sessionStorage.clear();
}
const getUser = () => store.getItem(us_er);
const getSchoolId = () => store.getItem(schoolID)
const getIdentityNo = () => store.getItem(identityNo__6)
const setAuthorization = token => store.setItem('Authorization', 'bearer ' + token);
const getAuthorization = token => store.getItem('Authorization');
const setPassWord = pass => store.setItem('pass', md5(pass));
const getPassWord = () => store.getItem('pass');
export { setPassWord, getPassWord, setToken, isLogin, clear, getUser, setSchoolId, getSchoolId,getIdentityNo, setIdentityNo, getRememberItem, setRememberItem, clearRemember, setOrgId, getOrgId, setAuthorization, getAuthorization }