<template>
  <div class="layouts w-100_ h-100_">
    <div class="layouts-header d-f jc-sb ai-c">
      <div class="header-logo d-f ai-c c-py">
        <img width="32px" src="@/assets/logo.png" alt="" />
        <strong>洛克侠</strong>
      </div>
      <div class="menu-tabs">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            v-for="item in tabs"
            :label="item.label"
            :key="item.name"
            :name="item.name"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="d-f ai-c jc-sb logout">
        <span class="c-py" style="color: #fe6900">{{ username }}</span>

        <el-popover placement="bottom" width="200" trigger="click">
          <span class="c-p" slot="reference">退出登录</span>
          <div class="d-f ai-c">
            <img
              src="@/assets/warn.png"
              style="margin-right: 16px; width: 20px"
              alt=""
            />
            确定要退出登录吗？
          </div>
          <div style="margin-top: 16px" class="d-f ai-c w-100_">
            <el-button
              size="small"
              type="primary"
              class="w-100_"
              @click="logout"
              >退 出</el-button
            >
          </div>
        </el-popover>
      </div>
    </div>
    <div class="layouts-content">
      <transition :name="transitionName">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { isLogin, getUser, clear, isMM } from "./common/store";
import { mapMutations } from "vuex";
export default {
  name: "Layouts",
  data() {
    return {
      username: "",
      transitionName: "slide-left",
      activeName: this.$route.path.split("/").filter(Boolean)[0],
      tabs: [
        { name: "room", label: "房间管理" },
        { name: "student", label: "学生管理" },
        { name: "housemaster", label: "宿管信息" },
        { name: "unlock", label: "开锁记录" },
        { name: "clock", label: "打卡记录" },
        { name: "setting", label: "系统设置" },
      ],
    };
  },
  methods: {
    ...mapMutations("login", ["setIsMM"]),
    logout() {
      clear();
      this.$router.push("/login");
    },
    handleClick() {},
  },
  beforeMount() {
    if (!isLogin()) {
      this.$router.push("/login");
    }
    const isM = isMM();
    this.setIsMM(isM);
    if (isM) {
      this.$delete(this.tabs, 5);
    }
  },
  mounted() {
    this.username = getUser();
  },
  watch: {
    activeName(val) {
      this.$route.path.split("/").filter(Boolean)[0] != val &&
        this.$router.push("/" + val);
    },
    $route() {
      if (!isLogin()) {
        this.$router.push("/login");
      }
      this.activeName = this.$route.path.split("/").filter(Boolean)[0];
      let isBack = this.$router.isBack; // 监听路由变化时的状态为前进还是后退
      if (isBack) {
        this.transitionName = "slide-right";
      } else {
        this.transitionName = "slide-left";
      }
      this.$router.isBack = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.layouts {
  .layouts-header {
    height: 60px;
    padding: 0 30px;
    .header-logo {
      width: 200px;
      font-weight: 500;
      color: #ff6a00;
      img {
        margin-right: 8px;
      }
    }
    .menu-tabs {
      width: calc(100% - 500px);
      /deep/ .el-tabs__nav {
        height: 60px;
      }
      /deep/ .el-tabs__item {
        padding: 0 66px 0 0;
        font-size: 13px;
        margin-top: 10px;
        font-weight: 400;
        color: #000000;
      }
      /deep/ .el-tabs__item.is-active {
        color: #e96f0d;
      }
      /deep/ .el-tabs__active-bar {
        background: #e96f0d;
        height: 4px;
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
      /deep/ .el-tabs__nav-wrap {
        &::after {
          display: none;
        }
      }
    }
    .logout {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 400;
      span {
        margin: 0 8px;
      }
    }
  }
  .layouts-content {
    height: calc(100vh - 60px);
  }
}
</style>
