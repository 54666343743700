<template>
  <div>
    <el-table
      ref="table"
      v-bind="$attrs"
      v-on="$listeners"
      @selection-change="handleSelectionChange"
      :data="data"
      :height="height"
    >
      <div slot="empty">
        <div class="text-align:center">
          <img
            style="width: 30px; margin: 100px auto auto auto; display: block"
            src="@/assets/nodata.png"
            alt=""
          />

          <span style="margin-top: 8px; color: #262626; font-size: 12px">
            暂无数据
          </span>
        </div>
      </div>
      <el-table-column align="left" :width="indexWidth || 24" label="">
      </el-table-column>
      <slot></slot>
    </el-table>
    <common-page
      @current-change="currentChange"
      :current-page="querySearch.pageNum"
      :page-size="querySearch.pageSize"
      @size-change="handleSizeChange"
      background
      :page-sizes="[50, 100, 150, 200]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></common-page>
  </div>
</template>

<script>
import CommonPage from "./commonPaginations.vue";
import { storeChecked } from "../views/common/index";
export default {
  name: "commin-table",
  components: {
    CommonPage,
  },
  data() {
    return {
      height: null,
      params: {}, //  存储的参数
    };
  },
  props: {
    short: Boolean,
    queryTableData: Function,

    total: Number,
    setQuerySearch: Function,
    setQuerySearchPageNum: Function,
    setQuerySearchPagesize: Function,
    querySearch: Object,
    indexWidth: String,
    data: Array,
  },
  methods: {
    getData() {
      const { params } = this;
      this.queryTableData && this.queryTableData({ ...params });
    },
    currentChange(pageNum) {
      if (this.setQuerySearchPageNum) {
        this.setQuerySearchPageNum(pageNum);
        this.getData();
      }
    },
    toggleSelection(rows) {
      setTimeout(() => {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.table.toggleRowSelection(row);
          });
        } else {
          this.$refs.table.clearSelection();
        }
      }, 10);
    },
    handleSizeChange(pageSize) {
      if (this.setQuerySearchPagesize && this.setQuerySearchPageNum) {
        this.setQuerySearchPageNum(1);
        this.setQuerySearchPagesize(pageSize);
        this.getData();
      }
    },
    paramsChange(params) {
      if (this.setQuerySearchPagesize && this.setQuerySearchPageNum) {
        this.params = params;
        this.setQuerySearchPageNum(1);
        this.setQuerySearchPagesize(50);
        this.getData();
      }
    },
    doLayout() {
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    },
    handleSelectionChange(val) {
      // 多选的item
      this.s.setPage(val, this.querySearch.pageNum);
      this.$emit("selectionChange", this.s.getAll());
    },
  },
  watch: {
    data(val) {
      const getPages = this.s
        .getpage(this.querySearch.pageNum)
        .map((it) => it.id);
      this.$nextTick(() => {
        this.toggleSelection(val.filter((it) => getPages.includes(it.id)));
      });
    },
  },
  mounted() {
    this.paramsChange({});
    this.height =
      document.body.clientHeight - 235 + (this.short ? -18 : 0) + "px";
    this.s = new storeChecked();
  },
};
</script>

<style>
.el-table__empty-text {
  line-height: 30px;
}
</style>