<template>
  <div
    style="
      height: 58px;
      display: flex;
      align-items: center;
      text-align: right;
      padding-right: 30px;
      justify-content: flex-end;
     background: #FBFBFB;
    "
  >
    <el-pagination
      :page-sizes="[50, 100, 150, 200]"
      v-bind="$attrs"
      v-on="$listeners"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>