import ajax, { $get, $post, $put } from '../../plugins/axios';
import { setToken, setSchoolId, setOrgId, setAuthorization, setPassWord ,setIdentityNo} from '@/views/common/store'
const tansParams = (params) => {
	let result = "";
	Object.keys(params).forEach((key) => {
		if (
			!Object.is(params[key], undefined) &&
			!Object.is(params[key], null)
		) {
			result +=
				encodeURIComponent(key) +
				"=" +
				encodeURIComponent(params[key]) +
				"&";
		}
	});
	return result;
}
export default {
	namespaced: true,
	state: {
		username: "",
		isMM: false,
	},
	mutations: {
		setUsername: (state, payload) => {
			state.username = payload;
		},
		setIsMM(state, payload) {
			state.isMM = payload
		}
	},
	actions: {
		login({ state }, param) {
			/// 登录接口
			console.log(state)
			return new Promise((res, rej) => {
				const params = {
					username: "",
					password: "",
					bindUsername: "",
					bindPassword: "",
					signUsername: "",
					signPassword: "",
					code: "",
					key: "",
					grant_type: "password",
					...param
				}
				$post('/auth/oauth/token', params, {
					transformRequest: [(params) => {
						params.universityId && delete params.universityId;
						return tansParams(params)
					}],
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'Authorization': 'Basic ZmViczoxMjM0NTY=',
					},
					isLogin: true,
					needAll: true
				}).then((response) => {
					setAuthorization(response.data.access_token);
					setToken(param.username);
					setPassWord(param.password)
				
					res()
				}).catch(rej)
			})
		},
		getAllSchoolByPhone({ state }, mobile) {
			return new Promise(response => {
				$post('/lock/user/checkPhone', {

					mobile

				}, { isLogin: true, needAll: true }).then(res => {

					if (res.data.code == 200) {
						response(true)
					} else {
						response(false)
					}
				}).catch(() => {
					response(false)
				})
			})
		},
		getSchoolIdByName({ state }, universityName) {
			return new Promise(res => {
				ajax.get('/lock/universityInfo/getUniversityInfoByUniversityName', { params: { universityName }, isLogin: true, needToekn: true }).then(data => {
					setSchoolId(data.id || 1);
					setOrgId(data.orgId);
					setIdentityNo(data.identityNo)
					res();
				})
			})
		},
		getVerifivationCode({ state }, mobile) {
			return $put('/lock/user/getVerificationCode', { mobile }, { isLogin: true })
		},
		resetPass({ state }, params) {
			return $put('/lock/user/resendNewPassword', { ...params }, { isLogin: true, needAll: true })
		},
		getNewPass({ state }, params) {
			//  获取新密码
			console.log(params, state)
			return Promise.resolve("1234")
		},
		getPhoneEnd() {   //  获取手机尾号
			return Promise.resolve("1234")
		}
	}

}