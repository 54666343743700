<template>
	<el-input v-bind="$attrs" v-on="$listeners" autocomplete='new-password'></el-input>
</template>

<script>
export default {

}
</script>

<style>

</style>